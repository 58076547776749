<template>
    <section class="page-section categories-intro-section scroll-icon">
        <h2 class="section-title">
            <Skeletor width="40%" />
        </h2>
        <ul class="all-categories_content">
            <li class="dosiin_all-categories all-categories" v-for="i in 20" :key="i">
                <a>
                    <Skeletor width="78" height="78" circle />
                </a>
                <a class="all-categories_name">
                    <Skeletor width="100%" />
                </a>
                <div class="all-categories_numbers">
                    <Skeletor width="100%" />
                </div>
            </li>
        </ul>
    </section>
</template>