<template>
    <div>
        <Intersection
            @onIntersection="onIntersectionProductRanking()"
        >
            <ProductBestSellerSkeletons v-if="!state.showProducts" />
            <section v-else class="page-section">
                <div class="section-title-wrapper">
                    <h2 class="section-title">Top sản phẩm</h2>
                </div>
                <div class="dosiin_product-best-selling swiper-filter-container">
                    <ul class="bestsellers-list">
                        <template  v-for="(product , index) in state.products.slice(0,5)" :key="index">
                            <li class="list-item bestsellers-list-item">
                                <ProductHorizon :product="product" :rating="index+1"/>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="seemore-btn">
                    <router-link to="/trending?style=ranking" class="primary-button button"> 
                        <span class="primary-link_text">Xem thêm</span>
                        <i class="dsi dsi-act-arrow-forward"></i>
                    </router-link>
                </div>
            </section>
        </Intersection>

        <Intersection
            @onIntersection="onIntersectionBrandRanking()"
        >
            <BrandVerticalRanking 
                title="Top thương hiệu"
                link="/brands-highlight"
                :brands="state.brands.slice(0,5)"
            />
        </Intersection>

        <!-- <Intersection
            @onIntersection="onIntersectionListCategory()"
        >
            <CategoriesListSkeletons v-if="state.categories.length === 0" />
            <section v-else class="page-section categories-intro-section scroll-icon"> -->
        <ais-instant-search
            :search-client="searchClientKeys"
            index-name="dev_categories_top_level_1"
        >
            <ais-configure
                :hitsPerPage="20"
            />
            <ais-hits 
                :class-names="{
                    'ais-Hits': 'page-section categories-intro-section scroll-icon',
                }"
            >
                <template v-slot="{ items }">
                    <h2 class="section-title">Top danh mục</h2>
                    <ul class="all-categories_content">
                        <template v-for="(cat , i ) in items">
                            <li class="dosiin_all-categories all-categories" :key="i"  v-if="i < 12">
                                <router-link class="all-categories_icon" :to="cat.seo_name" :title="cat.category"> 
                                    <i class="dsi dsi-s48" :class="getCategoryIcon(cat.category_id)"></i> 
                                </router-link>
                                <router-link class="all-categories_name" :to="_categoryUrl(cat)" :title="cat.category">{{cat.category}}</router-link>
                                <div class="all-categories_numbers">{{numberFormat(cat.product_count)}} sản phẩm</div>
                            </li>
                        </template>
                    </ul>
                </template> 
            <!-- </section> -->
            </ais-hits>
        </ais-instant-search>

        <!-- </section>
        </Intersection> -->
    </div>
</template>

<script>
    import ProductHorizon from '@/components/product/ProductItemHorizon';
    import ProductBestSellerSkeletons from '@/components/skeletons/sections/ProductBeseller';
    import BrandItemVertical from '@/components/brand/BrandItemVertical';
    import CategoriesListSkeletons from '@/components/skeletons/sections/CategoriesList';
    import BrandVerticalRanking from '@/components/brand/BrandVerticalRanking';
    import { inject} from '@vue/runtime-core';
    export default {
        components:{
            ProductHorizon,
            BrandItemVertical,
            ProductBestSellerSkeletons,
            BrandVerticalRanking,
            CategoriesListSkeletons
        },
        setup() {
            const state = inject('state');
            const onIntersectionProductRanking = inject('onIntersectionProductRanking');
            const onIntersectionBrandRanking = inject('onIntersectionBrandRanking');
            const onIntersectionListCategory = inject('onIntersectionListCategory');

            return {
                state,
                onIntersectionProductRanking,
                onIntersectionBrandRanking,
                onIntersectionListCategory
            }
        },
    }
</script>