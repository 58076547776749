<template>
    <RankingMB v-if="layout == 4"/>
    <RankingPC v-else/>
</template>

<script>
    import RankingMB from '@/pages/mb/home/Ranking';
    import RankingPC from '@/pages/pc/home/Ranking';
    import { provide , reactive } from 'vue';
    import { serialize } from '@/utlis/serialize';
    import DataService from '@/services/dosiin';

    export default {
        name : 'ranking',
        components:{
            RankingMB,
            RankingPC
        },
        setup(){
            const state = reactive({
                loading : false,
                products : [],
                brands : [],
                categories : [],
                showProducts : false,
                showBrands : false
            });

            async function onIntersectionProductRanking () {
                const response = await DataService.fetchData(serialize({type : 'trending'}));
                if(response.data){
                    state.products = Object.values(response.data.products);
                    state.showProducts = true;
                }
            }

            async function onIntersectionBrandRanking(){
                const response = await DataService.fetchData(serialize({type : 'highlight' , getBrand : true , limit : 60 }));
                if(response.data){
                    state.brands = Object.values(response.data.brands);
                    state.showBrands = true;
                }
            }

            // async function onIntersectionListCategory(){
            //     const response = await DataService.fetchData(serialize({type : 'top' , getCategory : true }));
            //     if(response.data){
            //         state.categories = Object.values(response.data);
            //     }
            // }

            provide('state',state);
            provide('onIntersectionProductRanking',onIntersectionProductRanking);
            provide('onIntersectionBrandRanking',onIntersectionBrandRanking);
            // provide('onIntersectionListCategory',onIntersectionListCategory);
        }
    }
</script>