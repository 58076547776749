<template>
    <section class="page-section">
        <div class="section-title-wrapper">
            <SectionHeader />
        </div>
        <ul class="grid bestsellers-list" :class="gridClass">
            <template  v-for="i in number" :key="i">
                <li class="grid-item list-item bestsellers-list-item" v-if="i < 4">
                    <div class="ranking-product-card" style="width: 100%;">
                        <div class="ranking-product-card-top d-flex align-items-center dosiin_text-center">
                            <div class="ranking-product-card-top-left">
                                <div class="ranking-top-nummber">
                                    <span>{{ i }}</span>
                                    <div class="ranking-top-icon">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6665 11.3334H1.33317L1.94796 10.4091L7.99984 1.16671L14.0517 10.4091L14.6665 11.3334Z" fill="#25BDFE"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div class="ranking-top-view">
                                    <div class="ranking-top-content-sub">
                                        <Skeletor width="80%" /> 
                                    </div>
                                    <div class="ranking-top-content-main">
                                        <Skeletor width="100%" /> 
                                    </div>
                                </div>
                                <div class="ranking-top-review">
                                    <div class="ranking-top-content-sub">
                                        <Skeletor width="80%" /> 
                                    </div>
                                    <div class="ranking-top-content-main">
                                        <Skeletor width="100%" /> 
                                    </div>
                                </div>
                            </div>
                            <div class="ranking-product-card-top-right">
                                <a title="dosiin">
                                    <Skeletor  width="100%" height="287" /> 
                                </a>
                            </div>
                        </div>
                        <div class="ranking-product-card-bottom">
                            <div class="list-item">
                                <dl class="list-item_summary" style="background-color: unset;">
                                    <div class="product-list-item_name_wrapper">
                                        <dd class="name_brand"><a class="list-item_brand"><Skeletor width="150" heigth="36" /></a></dd>
                                        <dd class="list-item_name"><a class="list-item_product"><Skeletor width="238" heigth="36" /></a></dd>
                                    </div>
                                    <div class="list-item_sale product-list-item_price_wrapper">
                                        <Skeletor width="200" heigth="36" />
                                    </div>
                                </dl>
                            </div>
                        </div>  
                    </div>
                </li>
                <li v-else class="list-item bestsellers-list-item grid-item">
                    <ProductItemHorizon />
                </li>
            </template>
        </ul>
    </section>
</template>

<script>
    import ProductItemHorizon from '@/components/skeletons/product/ProductItemHorizon';
    import SectionHeader from '@/components/skeletons/sections/SectionHeader';
    import { toRefs } from '@vue/reactivity';

    export default {
        components:{
            ProductItemHorizon,
            SectionHeader
        },
        props:{
            number : {
                type : Number,
                default : 5
            },
            gridClass:{
                type : String,
                default : 'grid-11'
            }
        },
        setup(props){


            return {
                ...toRefs(props)
            }
        }
    }
</script>

