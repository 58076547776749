<template>
  <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
    <Intersection @onIntersection="onIntersectionProductRanking()">
      <ProductBestSellerSkeletons
        v-if="!state.showProducts"
        :number="9"
        gridClass="grid-33"
      />

      <section v-else class="page-section">
        <div class="section-title-wrapper">
          <h2 class="section-title section-title-product">Top sản phẩm</h2>
          <div class="product-see-all">
            <router-link
              class="primary-button"
              :to="{ name: 'trending' }"
              title="Xem thêm sản phẩm"
            >
              <span class="primary-link_text">Xem tất cả</span>
              <i class="dsi dsi-act-arrow-forward"></i>
            </router-link>
          </div>
        </div>
        <div>
          <ul class="grid grid-33 bestsellers-list">
            <li
              class="grid-item list-item bestsellers-list-item"
              v-for="(product, index) in state.products.slice(0, 9)"
              :key="index"
            >
              <ProductItemRanking
                v-if="index < 3"
                :product="product"
                :rating="index + 1"
              />
              <ProductHorizon v-else :product="product" :rating="index + 1" />
            </li>
          </ul>
        </div>
      </section>
    </Intersection>

    <Intersection @onIntersection="onIntersectionBrandRanking()">
      <!-- link="/dosiin-brands" -->

      <BrandHorizonRanking title="Top thương hiệu" :brands="state.brands" />
    </Intersection>

    <ais-instant-search
      :search-client="searchClientKeys"
      index-name="dev_categories_top_level_1"
    >
      <ais-configure :hitsPerPage="20" />

      <ais-hits
        :class-names="{
          'ais-Hits': 'page-section',
        }"
      >
        <template v-slot="{ items }">
          <div class="home_section-inner">
            <div class="section-title-wrapper">
              <h2 class="section-title">Top danh mục</h2>
            </div>
            <ul class="all-categories_content all-categories_content-list">
              <template v-for="(cat, i) in items">
                <li class="dosiin_all-categories" :key="i" v-if="i < 15">
                  <router-link
                    :to="cat.seo_name"
                    class="all-categories center all-category_item"
                  >
                    <div class="category_box-body">
                      <div class="all-categories_icon">
                        <i
                          class="dsi dsi-s36"
                          :class="getCategoryIcon(cat.category_id)"
                        ></i>
                      </div>
                      <div class="category_box-txt">
                        <div class="all-categories_name">{{ cat.category }}</div>
                        <div class="all-categories_numbers">
                          <b>{{ numberFormat(cat.product_count) }}</b> sản phẩm
                        </div>
                      </div>
                    </div>
                    <i class="dsi dsi-chevron-right"></i>
                  </router-link>
                </li>
              </template>
            </ul>
          </div>
        </template>
      </ais-hits>
    </ais-instant-search>
  </div>
</template>

<script>
import ProductBestSellerSkeletons from "@/components/skeletons/sections/ProductBesellerRanking";
import ProductItemRanking from "@/components/product/ProductTopRanking";
import ProductHorizon from "@/components/product/ProductItemHorizon";
import CategoriesSkeletons from "@/components/skeletons/sections/CategoriesRectangular";
import BrandHorizonRanking from "@/components/brand/BrandHorizonRanking";

import { inject } from "vue";
export default {
  components: {
    ProductItemRanking,
    ProductHorizon,
    ProductBestSellerSkeletons,
    CategoriesSkeletons,
    BrandHorizonRanking,
  },
  setup() {
    const state = inject("state");
    const onIntersectionProductRanking = inject("onIntersectionProductRanking");
    const onIntersectionBrandRanking = inject("onIntersectionBrandRanking");
    return {
      state,
      onIntersectionProductRanking,
      onIntersectionBrandRanking,
    };
  },
};
</script>
