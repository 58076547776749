<template>
    <div class="bestsellers-list-item_content">
        <a class="list-item_img bestsellers-list-item_img">
            <Skeletor width="114" height="114" />
        </a>
        <dl class="list-item_summary">
            <dt>
                <a class="list-item_brand">
                    <Skeletor width="30%" />
                </a>
            </dt>
            <dd class="list-item_name">
                <a>
                    <Skeletor width="100%" />
                </a>
            </dd>
            <div class="list-item_sale">
            </div>
            <dd class="list-item_price">
                    <Skeletor width="30%" />
            </dd>
            <dl class="list-item_feedback">
                <Skeletor width="80%" />
            </dl>
        </dl>
    </div>
</template>

