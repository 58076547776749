<template>
  <div class="bestsellers-list-item_before" v-if="rating">
    <div class="bestsellers-list-item_number">{{ rating }}</div>
  </div>

  <div class="bestsellers-list-item_content">
    <router-link
      class="list-item_img bestsellers-list-item_img"
      :to="_productUrl(product)"
      :title="product.product"
    >
      <img
        v-lazy="_productThumbnailSticker(product.main_pair, product.sticker.main_pair)"
        :alt="product.product"
        width="165"
        height="165"
      />
    </router-link>
    <dl class="list-item_summary">
      <dt>
        <router-link
          class="list-item_brand"
          :to="_brandUrl(product.seo_name_company)"
          :title="product.company_name"
        >
          {{ product.company }}
        </router-link>
      </dt>
      <dd class="list-item_name">
        <router-link :to="_productUrl(product)" :title="product.product">
          {{ product.product }}
        </router-link>
      </dd>
      <div class="list-item_sale">
        <template v-if="product.list_price > product.price">
          <dd class="inline-bl list-item_sale_origin">
            {{ currencyFormat(product.list_price) }}
          </dd>
          <dd class="inline-bl list-item_sale_discount">
            -{{ product.list_discount_prc }}%
          </dd>
        </template>
      </div>
      <dd class="list-item_price">
        {{ currencyFormat(product.price) }}
      </dd>
      <dl class="list-item_feedback">
        <dd class="product-item_rating">
          <star-rating
            :rating="product.average_rating ? parseInt(product.average_rating) : 0"
            :star-size="layout == 4 ? 12 : 16"
            :read-only="true"
            :show-rating="true"
            text-class="product-tem_social-text"
            inactive-color="#B3B3B3"
            active-color="#FFCC07"
            :star-points="[
              23,
              2,
              14,
              17,
              0,
              19,
              10,
              34,
              7,
              50,
              23,
              43,
              38,
              50,
              36,
              34,
              46,
              19,
              31,
              17,
            ]"
          />
        </dd>
        <dd class="product-item_n-comments">
          <i
            class="dsi"
            :class="[
              layout != 4 ? ' dsi-s16' : ' dsi-s12',
              product.total_post > 0 ? 'dsi-act-fill-chat' : 'dsi-fill-chat',
            ]"
            :style="product.total_post > 0 ? 'filter: opacity(0.7);' : ''"
          ></i>
          <span class="product-tem_social-text" v-text="product.posts_count"></span>
        </dd>
      </dl>
    </dl>
  </div>
</template>

<script>
import { toRefs } from "vue";
import StarRating from "vue-star-rating";
export default {
  components: {
    StarRating,
  },
  props: {
    rating: {
      type: Number,
      default: 0,
    },
    product: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    return {
      ...toRefs(props),
    };
  },
};
</script>

<style>
.vue-star-rating-rating-text {
  margin-top: 0 !important;
}
</style>
