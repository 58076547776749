<template>
    <section class="page-section">
        <div class="section-title-wrapper">
            <SectionHeader />
        </div>

        <div class="dosiin_product-best-selling swiper-filter-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <ul class="grid" :class="gridClass">
                        <li class="list-item bestsellers-list-item grid-item" v-for="i in number" :key="i">
                            <ProductItemHorizon />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="seemore-btn more">
            <Skeletor width="100%" height="36" pill />
        </div>
    </section>
</template>

<script>
    import ProductItemHorizon from '@/components/skeletons/product/ProductItemHorizon';
    import SectionHeader from '@/components/skeletons/sections/SectionHeader';
    import { toRefs } from '@vue/reactivity';

    export default {
        components:{
            ProductItemHorizon,
            SectionHeader
        },
        props:{
            number : {
                type : Number,
                default : 5
            },
            gridClass:{
                type : String,
                default : 'grid-11'
            }
        },
        setup(props){


            return {
                ...toRefs(props)
            }
        }
    }
</script>

