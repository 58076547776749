<template>
  <div class="ranking-product-card dosiin_w-100">
    <div class="ranking-product-card-top d-flex align-items-center">
      <div class="ranking-product-card-top-left dosiin_text-center">
        <div class="ranking-top-nummber" v-if="rating">
          <span>{{ rating }}</span>
          <div class="ranking-top-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.6665 11.3334H1.33317L1.94796 10.4091L7.99984 1.16671L14.0517 10.4091L14.6665 11.3334Z"
                fill="#25BDFE"
              ></path>
            </svg>
          </div>
        </div>

        <div class="ranking-top-buy">
          <div class="ranking-top-content-sub">Đã bán</div>
          <div
            class="ranking-top-content-main"
            v-text="numberFormat(parseInt(product.sold))"
          ></div>
        </div>

        <div class="ranking-top-view">
          <div class="ranking-top-content-sub">Lượt đánh giá</div>
          <div
            class="ranking-top-content-main"
            v-text="numberFormat(parseInt(product.posts_count))"
          ></div>
        </div>
        <div class="ranking-top-review">
          <div class="ranking-top-content-sub">Đánh giá</div>
          <div class="ranking-top-content-main">
            <div class="d-flex align-items-center justify-content-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.9161 9.72832C21.8205 9.43068 21.6446 9.16538 21.408 8.96181C21.1714 8.75823 20.8833 8.62431 20.5755 8.57488L15.7459 7.78742L13.5071 3.42254C13.3645 3.14466 13.1484 2.91157 12.8824 2.74883C12.6164 2.58609 12.3109 2.5 11.9994 2.5C11.6879 2.5 11.3824 2.58609 11.1164 2.74883C10.8504 2.91157 10.6343 3.14466 10.4917 3.42254L8.25286 7.78742L3.4234 8.57488C3.11593 8.62509 2.82818 8.7593 2.59174 8.96278C2.35529 9.16627 2.17927 9.43116 2.08301 9.72841C1.98674 10.0257 1.97394 10.3438 2.04601 10.6478C2.11808 10.9519 2.27224 11.2302 2.49157 11.4521L5.9374 14.9372L5.19157 19.7887C5.14371 20.0977 5.18172 20.414 5.30142 20.7027C5.42112 20.9914 5.61787 21.2415 5.87003 21.4253C6.1222 21.6091 6.42 21.7196 6.73069 21.7446C7.04139 21.7696 7.35293 21.7081 7.63107 21.5669L11.9994 19.3559L16.3679 21.5669C16.646 21.7078 16.9575 21.769 17.2681 21.7439C17.5786 21.7188 17.8763 21.6083 18.1284 21.4246C18.3804 21.2408 18.5772 20.9909 18.697 20.7023C18.8167 20.4138 18.8549 20.0976 18.8074 19.7887L18.0615 14.9372L21.5074 11.4521C21.7274 11.2306 21.882 10.9523 21.9541 10.6481C22.0263 10.3438 22.0131 10.0255 21.9161 9.72832Z"
                  fill="#FFCC07"
                ></path>
              </svg>
              <span
                class="dosiin_ml-2"
                v-text="
                  product.average_rating ? `${parseInt(product.average_rating)}/5` : '0/5'
                "
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="ranking-product-card-top-right">
        <router-link :to="_productUrl(product)" :title="product.product">
          <img
            v-lazy="
              _productThumbnailSticker(product.main_pair, product.sticker.main_pair)
            "
            height="287"
            width="287"
          />
        </router-link>
      </div>
    </div>
    <div class="ranking-product-card-bottom">
      <div class="list-item">
        <dl class="list-item_summary" style="background-color: unset">
          <div class="product-list-item_name_wrapper">
            <dd class="name_brand">
              <router-link
                class="list-item_brand"
                :to="_brandUrl(product.seo_name_company)"
                :title="product.company_name"
                v-html="product.company_name"
              ></router-link>
            </dd>
            <dd class="list-item_name">
              <router-link
                class="list-item_product"
                :to="_productUrl(product)"
                :title="product.product"
                v-html="product.product"
              ></router-link>
            </dd>
          </div>
          <div class="list-item_sale product-list-item_price_wrapper">
            <dd
              class="inline-bl list-item_sale_origin"
              v-text="
                product.list_price > product.price
                  ? `${currencyFormat(product.list_price)}`
                  : ''
              "
            ></dd>
            <dd
              class="inline-bl list-item_sale_discount"
              v-text="
                product.list_price > product.price ? `-${product.list_discount_prc}%` : ''
              "
            ></dd>
            <dd class="list-item_price">{{ currencyFormat(product.price) }}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from "vue";
import StarRating from "vue-star-rating";
export default {
  components: {
    StarRating,
  },
  props: {
    rating: {
      type: Number,
      default: 0,
    },
    product: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    return {
      ...toRefs(props),
    };
  },
};
</script>
